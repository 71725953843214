<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">You're Early</h2>
        </div>

        <img
          src="@/assets/images/pages/rocket.png"
          alt="coming-soon"
          class="mx-auto mb-2"
          width="150"
        />

        <div
          class="count-down text-center"
          v-if="activation && activation.start"
        >
          <!-- <countdown
            :time="
              new Date(new Date(activation.start) || new Date()).getTime() -
              new Date().getTime()
            "
          >
            <template slot-scope="props">
              <div class="single-counter">
                <span class="timer">{{ props.days }}</span>
                <span>Days</span>
              </div>

              <div class="single-counter">
                <span class="timer">{{ props.hours }}</span>
                <span>Hours</span>
              </div>

              <div class="single-counter">
                <span class="timer">{{ props.minutes }}</span>
                <span>Minutes</span>
              </div>

              <div class="single-counter">
                <span class="timer">{{ props.seconds }}</span>
                <span>Seconds</span>
              </div>
            </template>
          </countdown> -->
        </div>

        <vs-divider position="center">More Information</vs-divider>

        <div class="subscription">
          <span
            >You were issued a license that will not work until
            <strong>{{ parseDate(activation.start) }}</strong
            >. Please set your date if invalid or request a license that covers
            you.</span
          >
          <vs-button
            class="w-full mt-6"
            @click="$router.push({ name: 'license' })"
            >Change License</vs-button
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import idb from "@/db/idb";
// import VueCountdown from "@chenfengyuan/vue-countdown";
import m from "moment";

export default {
  data() {
    return {
      activation: {},
    };
  },
  components: {
    // countdown: VueCountdown,
  },
  methods: {
    addDays(date, days) {
      const c = new Date(Number(date));
      c.setDate(date.getDate() - days);
      return c;
    },
    parseDate(d) {
      return this.$moment(d).format("dddd, MMMM Do YYYY HH:mm a");
    },
  },
  async created() {
    this.activation = await idb.getRecord("activation");
    var s = m(new Date(this.activation.start)).isAfter(new Date(Date.now()));
    if (!s) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>

